import { ApolloError, gql } from "@apollo/client";
import { Box, FormControl, InputLabel, Popover, Stack } from "@mui/material";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { debounce, throttle } from "lodash";
import { useSnackbar } from "notistack";
import {
  PaginatedAgentsTableQuery,
  PipelineType,
  useAgentsTableChangedSubscription,
  usePaginatedAgentsTableQuery,
} from "../../graphql/generated";
import { selectorString } from "../../types/configuration";
import { EEAgentsDataGrid } from "../EEAgentsTable/EEAgentsDataGrid";
import { muiSortModelToString } from "../EEAgentsTable/EEAgentsTable";
import { AgentMetrics, AgentsTableField } from "../EEAgentsTable/types";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";
import { SearchBar } from "../SearchBar";
import { SelectButton } from "./SelectButton";
import { useSnapshot } from "./SnapshotContext";
import { classes } from "../../utils/styles";

import styles from "./agent-select.module.scss";

gql`
  query agentsWithConfiguration($selector: String, $query: String) {
    agents(selector: $selector, query: $query) {
      agents {
        id
        name
      }
    }
  }
`;

type TableAgent = PaginatedAgentsTableQuery["agentsTable"]["agents"][0];

interface AgentsTableSelectProps {
  initQuery?: string;
  label?: string;
  agentID?: string;
  query?: string;
  onChange: (agentID: string) => void;
}

export const AgentsTableSelect: React.FC<AgentsTableSelectProps> = ({
  label,
  agentID,
  query = "",
  onChange,
}) => {
  label ||= "Agent";

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 25,
    page: 0,
  });

  // Initial sort by agent name
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: AgentsTableField.NAME,
      sort: "asc",
    },
  ]);
  const [rowCount, setRowCount] = useState(0);
  const [agents, setAgents] = useState<TableAgent[]>([]);
  const [agentMetrics, setAgentMetrics] = useState<AgentMetrics>();
  const [suggestionsQuery, setSuggestionsQuery] = useState<string>();
  const [suggestions, setSuggestions] =
    useState<PaginatedAgentsTableQuery["agentsTable"]["suggestions"]>();
  const [loading, setLoading] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  // get the configuration to use the selector on the agent table
  const { configuration } = usePipelineGraph();
  const selector = selectorString(configuration?.spec?.selector);
  // The element the popover is anchored to
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // Whether the popover is open
  const open = Boolean(anchorEl);
  const [displayAgentID, setDisplayAgentID] = useState("Loading...");

  const { pipelineType } = useSnapshot();
  const columnFields = [AgentsTableField.NAME];
  switch (pipelineType) {
    case PipelineType.Logs:
      columnFields.push(AgentsTableField.LOGS);
      break;
    case PipelineType.Metrics:
      columnFields.push(AgentsTableField.METRICS);
      break;
    case PipelineType.Traces:
      columnFields.push(AgentsTableField.TRACES);
      break;
  }

  function onCompleted(data: PaginatedAgentsTableQuery) {
    setRowCount(data.agentsTable.agentCount);
    setAgents(data.agentsTable.agents);
    setSuggestionsQuery(data.agentsTable.query);
    setSuggestions(data.agentsTable.suggestions);
    setAgentMetrics(data.agentsTable.agentMetrics);
    if (data.agentsTable.agents.length > 0) {
      if (agentID) {
        const agent = data.agentsTable.agents.find((a) => a.id === agentID);
        if (agent) {
          setDisplayAgentID(agent.name);
        }
      } else {
        onChange(data.agentsTable.agents[0].id);
        setDisplayAgentID(data.agentsTable.agents[0].name);
      }
    } else {
      setDisplayAgentID("No Agents");
    }
    setLoading(false);
  }

  function handleError(err: ApolloError) {
    console.error(err);
    enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
    setLoading(false);
  }

  const { refetch } = usePaginatedAgentsTableQuery({
    fetchPolicy: "network-only",
    variables: {
      options: {
        query: suggestionsQuery ?? query,
        selector,
        offset: paginationModel.page * paginationModel.pageSize,
        limit: paginationModel.pageSize,
        sort: muiSortModelToString(sortModel),
      },
    },
    onCompleted,
    onError: handleError,
  });

  // the debouncedRefetch is used to refetch when the search bar query changes
  const debouncedRefetch = useMemo(() => debounce(refetch, 200), [refetch]);

  // the throttledRefetch is used to refetch when the
  // we get notified of a table change by the subscription
  const throttledRefetch = useMemo(() => throttle(refetch, 1000), [refetch]);

  useAgentsTableChangedSubscription({
    variables: {
      agentIDs: agents.map((a) => a.id),
      selector,
    },
    onData() {
      throttledRefetch({
        options: {
          selector,
          query: suggestionsQuery ?? "",
          offset: paginationModel.page * paginationModel.pageSize,
          limit: paginationModel.pageSize,
          sort: muiSortModelToString(sortModel),
        },
      });
    },
  });

  function onQueryChange(newQuery: string) {
    debouncedRefetch({
      options: {
        selector,
        query: newQuery,
        offset: 0,
        limit: paginationModel.pageSize,
        sort: muiSortModelToString(sortModel),
      },
    });
  }

  function handlePaginationChange(newPaginationModel: GridPaginationModel) {
    setLoading(true);
    setPaginationModel(newPaginationModel);
  }

  function handleSortChange(newSortModel: GridSortModel) {
    setLoading(true);
    setSortModel(newSortModel);
  }

  function handleAgentClick(agentID: string, agentName: string) {
    onChange(agentID);
    setDisplayAgentID(agentName);
    setAnchorEl(null);
  }

  return (
    <>
      <FormControl>
        <InputLabel
          className={classes([styles["label"], open ? styles.open : undefined])}
        >
          {label}
        </InputLabel>
        <SelectButton
          label={displayAgentID}
          open={open}
          setAnchorEl={setAnchorEl}
        />
      </FormControl>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          transform: "translateY(1px)", // Adjust this value as needed to shift the Popover down
        }}
        elevation={2}
        slotProps={{
          paper: {
            variant: "elevation",
          },
        }}
      >
        <Box margin="8px" height="60vh">
          <Stack spacing={1} height="100%">
            <SearchBar
              suggestions={suggestions}
              onQueryChange={onQueryChange}
              suggestionQuery={suggestionsQuery}
              initialQuery={query}
            />
            <EEAgentsDataGrid
              allowSelection={false}
              density="compact"
              loading={loading}
              agents={agents}
              agentMetrics={agentMetrics}
              columnFields={columnFields}
              paginationModel={paginationModel}
              onPaginationModelChange={handlePaginationChange}
              sortModel={sortModel}
              onSortModelChange={handleSortChange}
              rowCount={rowCount}
              onAgentClick={handleAgentClick}
            />
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
