import {
  Card,
  CardContent,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleLoginResponse } from "./handleLoginResponse";
import { BindplaneVersion } from "../../components/BindplaneVersion";
import { BindPlaneLogo } from "../../components/Logos";
import lights from "../../lights.png";
import { edition } from "../../components/BindplaneVersion/utils";
import { parseErrorResponse } from "../../utils/rest/parse-error-response";

import styles from "./login.module.scss";

export const UsernameLogin: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState<string | null>();
  const [invalidCreds, setInvalidCreds] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    setSubmitting(true);
    setInvalidCreds(false);
    setUnauthorizedError(null);

    e.preventDefault();

    if (formRef.current == null) {
      setSubmitting(false);
      return;
    }

    const invitationId = searchParams.get("invitation");
    const data = new FormData();
    data.append("u", username);
    data.append("p", password);
    if (invitationId != null) {
      data.append("invitation", invitationId);
    }

    const resp = await fetch("/login", {
      method: "POST",
      body: data,
    });

    setSubmitting(false);
    await handleLoginResponse({
      status: resp.status,
      onSuccess: async () => {
        if (invalidCreds) {
          setInvalidCreds(false);
          setUnauthorizedError(null);
        }
        localStorage.setItem("user", username);
        navigate("/overview");
      },
      on401: async () => {
        const errors = await parseErrorResponse(resp);
        if (errors != null && errors.length > 0) {
          setUnauthorizedError(errors.join(", "));
          return;
        }
        setInvalidCreds(true);
      },
      on403: async () => {
        if (invalidCreds) {
          setInvalidCreds(false);
        }
        localStorage.setItem("user", username);

        edition() === "Free"
          ? navigate("/getting-started")
          : navigate("/organization/new");
      },
      on409: async () => {
        if (invalidCreds) {
          setInvalidCreds(false);
        }
        setUnauthorizedError(null);
        enqueueSnackbar("Sorry, the invitation is invalid.", {
          variant: "error",
        });
      },
      onFailure: async () => {
        enqueueSnackbar("Oops! Something went wrong.", {
          variant: "error",
        });
      },
    });
  }

  return (
    <>
      <div className={styles["login-page"]} data-testid="login-page">
        <img
          src={lights}
          alt="lights.png"
          width={"100%"}
          style={{
            position: "fixed",
            top: "-10rem",
            left: "-0.1rem",
          }}
        />
        <Stack alignItems={"center"} justifyContent={"center"}>
          <BindPlaneLogo width={225} height={60} className={styles.logo} />
          <Card classes={{ root: styles.card }}>
            <CardContent>
              <Typography variant="h5" fontWeight={600}>
                Sign In
              </Typography>
              <form
                action="/login"
                method="POST"
                ref={formRef}
                onSubmit={(e) => {
                  handleLogin(e);
                }}
              >
                <Stack>
                  <TextField
                    type="text"
                    label="Username"
                    name="username"
                    value={username}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setUsername(event.target.value);
                    }}
                    size="small"
                    margin="normal"
                    data-testid="username-input"
                  />
                  <TextField
                    type="password"
                    label="Password"
                    name="password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(event.target.value);
                    }}
                    size="small"
                    margin="normal"
                    data-testid="password-input"
                  />
                </Stack>

                <FormControl margin="normal" fullWidth>
                  {invalidCreds && (
                    <Typography variant="body2" color="error">
                      Invalid username or password.
                    </Typography>
                  )}
                  {unauthorizedError && (
                    <Typography variant="body2" color="error">
                      {unauthorizedError}
                    </Typography>
                  )}
                </FormControl>

                <FormControl margin="normal" fullWidth>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={username === "" || password === ""}
                    data-testid="sign-in-button"
                    loading={submitting}
                  >
                    Sign In
                  </LoadingButton>
                </FormControl>
              </form>
            </CardContent>
          </Card>
        </Stack>
      </div>
      <footer>
        <BindplaneVersion />
      </footer>
    </>
  );
};
