import { gql, useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Divider,
  Link,
  Menu,
  MenuItem,
  MenuList,
  MenuProps,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Role, useSettingsMenuQuery } from "../../../graphql/generated";
import { AuthType } from "../../../types/auth";
import { getAuthType } from "../../../utils/get-auth-type";
import { AccountSwitcher } from "../../AccountSwitcher";
import { BindplaneVersion } from "../../BindplaneVersion/BindplaneVersion";
import {
  BuildingIcon,
  EmailIcon,
  HelpCircleIcon,
  ListIcon,
  LogoutIcon,
  SlackIcon,
  ToolIcon,
  UsersIcon,
} from "../../Icons";
import { RBACWrapper } from "../../../contexts/RBAC";

import styles from "./settings-menu.module.scss";

gql`
  query settingsMenu {
    organization {
      metadata {
        id
        name
        version
      }
      licenseType
    }
    user {
      metadata {
        id
        name
        version
        displayName
      }
      spec {
        activeAccount
      }
      isOrgAdmin
    }
    accounts {
      metadata {
        id
        name
        version
        displayName
      }
    }

    multiAccountEnabled: featureGate(id: "multiaccount")
    auditTrailEnabled: featureGate(id: "audit-trail")
  }

  mutation changeAccount($input: ChangeAccountInput!) {
    changeAccount(input: $input) {
      metadata {
        name
        id
        version
        displayName
      }
      spec {
        activeAccount
      }
    }
  }
`;

/**
 * Settings menu for the top navigation bar.
 */
export const EESettingsMenu: React.FC<MenuProps> = ({
  anchorEl,
  open,
  onClose,
}) => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth0();

  const { data: userInfo } = useSettingsMenuQuery({
    onError: (err) => {
      console.error(err);
      enqueueSnackbar("Error getting user info", {
        variant: "error",
        key: "get-user-info-error",
      });
    },
  });

  async function handleLogout() {
    try {
      if (getAuthType() === AuthType.Auth0) {
        await logout({ openUrl: false });
      }
      localStorage.removeItem("user");
      await apolloClient.resetStore();
      await fetch("/logout", {
        method: "PUT",
      });
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      style={{
        left: "1.475rem",
      }}
      slotProps={{
        paper: {
          className: styles["settings-paper"],
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      MenuListProps={{
        "aria-labelledby": "settings-button",
      }}
      classes={{ paper: styles.menu }}
    >
      <div>
        <Stack className={styles["info-stack"]} marginBottom={1}>
          <Typography variant="h6">
            {userInfo?.organization?.metadata.name}
          </Typography>
        </Stack>
        <AccountSwitcher
          accounts={userInfo?.accounts}
          activeAccountId={userInfo?.user?.spec.activeAccount}
          orgAdmin={userInfo?.user.isOrgAdmin}
          multiAccountEnabled={userInfo?.multiAccountEnabled}
        />
      </div>

      <MenuList>
        {userInfo?.user.isOrgAdmin && (
          <MenuItem onClick={() => navigate("/organization")}>
            <BuildingIcon style={{ height: 24, width: 32 }} />
            Organization
          </MenuItem>
        )}
        {userInfo?.multiAccountEnabled && (
          <RBACWrapper requiredRole={Role.Admin}>
            <MenuItem onClick={() => navigate("/project")}>
              <UsersIcon className={styles["settings-icon"]} />
              Project
            </MenuItem>
          </RBACWrapper>
        )}
        {userInfo?.auditTrailEnabled && (
          <MenuItem onClick={() => navigate("/audit-logs")}>
            <ListIcon className={styles["settings-icon"]} />
            Audit Logs
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          component={Link}
          data-testid="slack-link"
          href="https://observiq.com/support-bindplaneop/"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <SlackIcon className={styles["settings-icon"]} />
            <Typography>Slack Community</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          component={Link}
          data-testid="support-link"
          href="mailto:support.bindplaneop@observiq.com"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <EmailIcon className={styles["settings-icon"]} />
            <Typography>Email Support</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          component={Link}
          data-testid="doc-link"
          href="https://observiq.com/docs/getting-started/quickstart-guide"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <HelpCircleIcon className={styles["settings-icon"]} />
            <Typography>Documentation</Typography>
          </Stack>
        </MenuItem>
        <MenuItem
          component={Link}
          data-testid="api-documentation-link"
          href="/swagger"
          underline="none"
          color="inherit"
          variant="inherit"
          target="_blank"
          rel="noopener"
        >
          <Stack direction={"row"}>
            <ToolIcon className={styles["settings-icon"]} />
            <Typography>API Documentation</Typography>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <LogoutIcon className={styles["settings-icon"]} />
          Logout
        </MenuItem>
      </MenuList>
      <Divider />

      <Stack
        direction="row"
        justifyContent="flex-end"
        paddingTop={1}
        paddingX={1}
      >
        <BindplaneVersion licenseType={userInfo?.organization.licenseType} />
      </Stack>
    </Menu>
  );
};
